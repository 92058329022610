/*@import url('https://fonts.googleapis.com/css2?family=Inter+Tight:wght@100;300;500;600&display=swap');*/
/*@import "~swiper/swiper-bundle.min.css";*/

:root {
    --swiper-navigation-color: #666;
    --swiper-navigation-size: 20px;
    --swiper-navigation-sides-offset: 0px;

}

body * {
    -webkit-font-smoothing: subpixel-antialiased !important;
    text-rendering:optimizeLegibility !important;
}

body {
    font-weight: 300;
    overflow-x: hidden;
}

main ul {
    @apply list-inside my-2
}

main li {
    @apply list-disc py-1
}

li > * {
    @apply inline
}

h1 {
    @apply text-4xl font-bold mb-3;
}

h2 {
    @apply text-3xl font-bold mb-3;
}

h3 {
    @apply text-2xl font-bold mb-3;
}

h4 {
    @apply text-xl font-bold mb-3;
}

b, strong {
    @apply font-bold;
}

u {
    @apply underline;
}

strike {
    @apply line-through;
}

.btn-primary {
    @apply bg-primary text-white hover:bg-secondary cursor-pointer;
}

.btn-secondary {
    @apply bg-secondary !text-white hover:bg-primary cursor-pointer;
}

.btn-white {
    @apply bg-white text-gray-700 flex w-full items-center justify-center rounded-md border border-transparent px-5 py-3 text-base font-medium hover:bg-secondary hover:text-white cursor-pointer shadow;
}

.btn-text {
    @apply text-indigo-500 hover:text-indigo-900;
}


.btn-white-xs {
    @apply bg-white text-gray-700 flex w-full items-center justify-center rounded-md border border-transparent px-3 py-2 text-xs font-medium hover:bg-secondary hover:text-white cursor-pointer shadow;
}

.btn-primary-xs {
    @apply bg-primary text-white flex w-full items-center justify-center rounded-md border border-transparent px-3 py-2 text-xs font-medium hover:bg-secondary hover:text-white cursor-pointer shadow;
}

.btn-secondary-xs {
    @apply bg-secondary text-white flex w-full items-center justify-center rounded-md border border-transparent px-3 py-2 text-xs font-medium hover:bg-primary hover:text-white cursor-pointer shadow;
}

.bg-pattern {
    background: #cdeb8e;
    background: -moz-linear-gradient(top, #cdeb8e 0%, #a5c956 100%);
    background: -webkit-linear-gradient(top, #cdeb8e 0%, #a5c956 100%);
    background: linear-gradient(to bottom, #cdeb8e 0%, #a5c956 100%);

    transform: rotateX(0deg) rotateY(169deg);
    transform-style: preserve-3d;
}

.bg-pattern-container {
    perspective: 250px;
    width: 100%;
    height: 100%;
}

.rich-text p {
    padding-bottom: 1rem;
}

.perspective-images {
    perspective: 250px;
    width: 100%;
    height: 100%;
}

.perspective-image {
    transform: perspective(219px) rotateX(57deg) rotateY(0deg) rotateZ(0deg)

}

/*.wavelayer1 {*/
/*    transform: translate(-56px, -89px);*/
/*    transform-origin: top left;*/
/*    min-width: 120vw;*/

/*    animation: fadeIn 1s ease-out;*/
/*    animation-fill-mode: forwards;*/
/*    animation-delay: 0s;*/
/*    opacity: 0;*/
/*}*/

.wavelayer2 {
    /*transform: translate(-40px, -178px);*/
    transform-origin: top left;
    /*max-width: 2100px;*/

    width: 130vw;
    /*animation: fadeIn 1s ease-out;*/
    /*animation-fill-mode: forwards;*/
    /*animation-delay: .4s;*/
    /*opacity: 0;*/

}

.wavelayer3 {
    /*transform: translate(-62px, -202px);*/
    transform-origin: top left;
    /*max-width: 2100px;*/
    width: 130vw;
    transition: 500ms;

    /*animation: fadeIn 1s ease-out, unblur 2s;*/
    /*animation-fill-mode: forwards;*/
    /*animation-delay: .8s;*/
    /*opacity: 0;*/

}

@media screen and (min-width: 1000px) {
    .wavelayer2 {
        width: 2000px;
        transform: translate(-48px, -78px) scale(1.3)
    }

    .wavelayer3 {
        width: 2000px;
        transform: translate(-70px, -90px) scale(1.3);
    }
}


@media screen and (min-width: 1350px) {
    .wavelayer2 {
        width: 3000px;
        transform: translate(-40px, -141px);
    }

    .wavelayer3 {
        width: 3000px;
        transform: translate(-62px, -153px);
    }
}


@media screen and (min-width: 1450px) {
    .wavelayer2 {
        width: 3000px;
        transform: translate(-40px, -153px);
    }

    .wavelayer3 {
        width: 3000px;
        transform: translate(-62px, -153px);
    }
}

@keyframes unblur {
    0% {
        filter: blur(8px);
    }
    100% {
        filter: blur(0px);
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
        /*margin-left: -25vw;*/
    }
    100% {
        opacity: 1;
        /*margin-left: 0vw;*/
    }
}

.video-bg video {
    max-width: initial !important;
    max-height: initial !important;
    width: 100%;
    height: 100%;
}

.imagehero-gradient {
    background: rgb(255, 255, 255);
    background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.9080225840336135) 26%, rgba(48, 62, 62, 0) 100%);
}

.lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}

.lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #333;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #333 transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
}

@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}


.infinite-scroll-component {
    overflow: unset !important;
}

blockquote a, blockquote a:visited, .link {
    @apply text-blue-600 underline hover:text-blue-900;
}

.terms h3 {
    @apply block;
}

.padding-lg {
    @apply py-20 sm:py-24 lg:py-24;
}

.padding-normal {
    @apply py-12 sm:py-16 lg:py-24;
}

.padding-sm {
    @apply py-8 sm:py-12 lg:py-20;
}

.padding-xs {
    @apply py-4 sm:py-6 lg:py-10;
}

.padding-xxs {
    @apply py-2 sm:py-4 lg:py-5;
}

.swiper-slide {
    height: auto !important;
}

.blog-swiper .swiper-wrapper {
}

.success-swiper .swiper-slide {
    height: auto !important;
}

.alert-warning {
    @apply border-yellow-400 bg-yellow-50 p-4 rounded-md;
}

.alert-info {
    @apply border-blue-400 bg-blue-50 p-4 rounded-md;
}

.alert-error {
    @apply border-red-400 bg-red-50 p-4 rounded-md;
}

code {
    line-height: 1.1;
    font-size: 13px;
}

a[linktype=url] {
    @apply text-blue-600 underline hover:text-blue-900;
}

.dot-class li {
    @apply list-none bg-gray-400 mx-2 p-0;
    height: 3px;
}
.react-multi-carousel-track {
    @apply !list-none;
}
.react-multi-carousel-item {
    @apply list-none;
}
.react-multi-carousel-dot--active {
    @apply !bg-secondary;
}


.hover-card .face {
    display: flex;
    flex-direction: column;
    @apply xl:opacity-0;
    transition: opacity .5s;
    position: absolute;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;

}

@media screen and (min-width: 1280px) {
    animation-delay: 3s;
    animation: fadeInOut ease-in 12s infinite;
}

@keyframes fadeInOut {
    0% {
        opacity: 0;
    }
    10% {
        opacity: 1;
    }
    90% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }

}

.hover-card:hover {
}

.hover-card:hover .face {
    opacity: 1;
}

.landing-headline {
    position: relative;
    z-index: 1;
}
.landing-headline h1 {
    @apply text-3xl lg:text-5xl;
}

.landing-headline b, .landing-headline strong, .stroked {
    position: relative;
    z-index: 1;
}
.landing-headline b:before, .landing-headline strong:before, .stroked {
    content: " ";
    position: absolute;
    z-index: -1;
    width: 110%;
    height: 110%;
    margin-left: -2%;
    background: url('/images/stroke.svg') no-repeat ;
    background-size: 100%;
    opacity: .8;
    overflow: visible;
    display: inline-block;
}

@media screen and (max-width: 640px) {
    .landing-page-hero .text-white {
        @apply text-gray-900;
    }


    .landing-page-hero .text-gray-100 {
        @apply text-gray-800;
    }

    .contact-form-tiny .contact-form-heading p {
        display: inline;
    }

    .landing-page-hero .intro-text {

    }
}

.proven-process dl div:hover svg {
    @apply text-secondary;
}

.blogpost h3 {
    @apply my-4;
}

.blogpost ul {
}

.blogpost li {
    @apply list-inside my-4 list-disc;
}
.blogpost p ~ ul>li {
    @apply mt-0;
}