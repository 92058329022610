//Colors
$black: #333;
$white: #ffffff;
$primary: #343747;
$platinum: platinum;
$green: #a6d046;

#endStep, #startStep {
  position: absolute;
  width: 15%;
  left: 85%;
  transition: opacity .5s;
  opacity: 0;
}

#startStep {
  left: 0;
  transform: rotate(180deg);
}

#endStep.step4 {
  opacity: 1;
}

#startStep.step1 {
  opacity: 1;
}

#waveStep {
  position: absolute;
  transition: all .5s ease-in-out;
}

#waveStep.step1 {
  width: 30%;
  left: 10%;
}

#waveStep.step2 {
  width: 30%;
  left: 35%;
}

#waveStep.step3 {
  width: 30%;
  left: 60%;
}

#waveStep.step4 {
  width: 30%;
  left: 60%;
}

#stepimage .active {
  fill: $green;
}


@keyframes fillTimeline {
  0% {
    width: 0%;
    height: 15px;
    background: $primary;
  }
  100% {
    width: 98%;
    height: 15px;
    background: $primary;
  }
}


.move-arrows {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  animation: 2s wiggleArrow infinite;
  animation-timing-function: ease-in-out;
}


@keyframes wiggleArrow {
  0% {
    transform: translateX(-50%);
  }
  50% {
    transform: translateX(-80%);
  }
  100% {
    transform: translateX(-50%);
  }
}



@media only screen and (min-width: 768px) {
  .timeline-secondary > .container {
    margin-bottom: 4rem;
  }
}